import { LinkProps as NextUILinkProps, useLink } from "@nextui-org/react";
import clsx from "clsx";
import { cloneElement, forwardRef, isValidElement, ReactNode } from "react";

export interface LinkProps
  extends Omit<NextUILinkProps, "showAnchorIcon" | "anchorIcon"> {
  /**
   * The icon to display after the text.
   */
  endIcon?: React.ReactNode;
  /**
   * The icon to display before the text.
   */
  startIcon?: React.ReactNode;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ endIcon, startIcon, ...props }, ref) => {
    const getIconClone = (icon: ReactNode) =>
      isValidElement(icon)
        ? cloneElement(icon, {
            // @ts-ignore
            "aria-hidden": true,
            height: "1em",
            width: "1em",
            stroke: "currentColor",
            className:
              "flex mx-1 text-current self-center leading-none group-visited:text-accent-dark group-hover:text-primary-dark",
          })
        : null;

    const startContent = getIconClone(startIcon);
    const endContent = getIconClone(endIcon);

    const { children, Component, getLinkProps } = useLink({
      ...props,
      className: clsx(
        "group rounded hover:opacity-100 hover:text-primary-dark hover:underline visited:text-accent-dark",
        props.className,
      ),
      ref,
    });

    return (
      <Component {...getLinkProps()}>
        <>
          {startContent}
          {children}
          {endContent}
        </>
      </Component>
    );
  },
);

Link.displayName = "RecareUI.Link";
